












import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import EmptyAnalysisPlaceholder from '../EmptyAnalysisPlaceholder.vue';
import HatcheryAnalysisPageHeader from './HatcheryAnalysisPageHeader.vue';
import { analysisStores } from '@/store/poultry/analysis/index';
import HatcheryAnalysisChart from './HatcheryAnalysisChart/HatcheryAnalysisChart.vue';
import HatcheryAnalysisTable from './HatcheryAnalysisTable/HatcheryAnalysisTable.vue';

@Component({
    components: {
        HatcheryAnalysisPageHeader,
        HatcheryAnalysisChart,
        HatcheryAnalysisTable,
        EmptyAnalysisPlaceholder
    }
})
export default class HatcheryAnalysis extends Vue {
    store = analysisStores.hatchery.list;

    created() {
        this.store.setHatcheryCreated(false);
    }

    get isHatcheryCreated() {
        return this.store.isCreated;
    }
}
