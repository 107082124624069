






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import HatcheryAnalysisChartHeader from './HatcheryAnalysisChartHeader.vue';
import HatcheryAnalysisChartBody from './HatcheryAnalysisChartBody.vue';

@Component({
  components: {
    HatcheryAnalysisChartHeader,
    HatcheryAnalysisChartBody
  }
})
export default class HatcheryAnalysisChart extends Vue {
}
