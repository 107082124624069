








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import HatcheryAnalysisTableHeader from './HatcheryAnalysisTableHeader.vue';
import HatcheryAnalysisTableBody from './HatcheryAnalysisTableBody.vue';

@Component({
  components: {
    HatcheryAnalysisTableHeader,
    HatcheryAnalysisTableBody,
  },
})
export default class HatcheryAnalysisTable extends Vue {}
