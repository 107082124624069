












































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import Dropdown from '@/components/poultry/Dropdown.vue';
import DropdownAlt from "@/components/poultry/DropdownAlt.vue";
import { DropdownOption } from '@/models/poultry/dropdown.model';

@Component({
  components: {
    Dropdown,
    DropdownAlt
  }
})

export default class DropdownCompareHatchery extends Vue {
  @Prop() sectionType!: [];
  @Prop() hasSection!: boolean;
  @Prop() reportTypeId !: string;

  // This one would be a fixed value
  comparisonType = [
    {text: 'clients', value: 'between_clients'},
  ]

  isActive = false;

  mounted() {
    document.addEventListener('click', this.closeDropdown);
  }

  destroyed() {
    document.removeEventListener('click', this.closeDropdown);
  }

  closeDropdown(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isActive = false;
    }
  }

  @Emit('onHandleComparisonTypeChanged')
  handleComparisonTypeChanged(value: DropdownOption) {}

  @Emit('onHandleSectionTypeChanged')
  handleSectionTypeChanged(value: DropdownOption) {}
}
